.bggreen{
	background-color: $green !important;
}
.bgyellow{
	background-color: $yellow !important;
}
.bgred{
	background-color: $red !important;
}
.bgblue{
	background-color: $blue !important;
}
.bgwhite{
	background-color: #ffffff !important;
}