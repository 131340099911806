// $yellow:#f6a403;
// $red:#e40f21;
// $darkred: #4c0000;
// $green:#37bb70;
// $blue: #01a1df;
// $grey: #1c2427;

$yellow:#f27d3a;
$red:#c03000;
$darkred: #4c0000;
$green:#00c0ba;
// $blue:#19a0d8;
$blue: #3a9ff2;
$grey: #1c2427;

$font_cindie: "Cindie A";


$siteWideSM: 520px;
$siteWideMD: 658px;
$siteWideLG: 850px;
$siteWideXL: 1200px;


.black{ color: black; }
.black-20{ color: rgba(black, 0.2); }
.black-50{ color: rgba(black, 0.5); }
.white{color: white;}
.blue{color: $blue !important;}

strong.white > a {
	color: white;
}

.w-2rem {
	width: 2rem;
}
