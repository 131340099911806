.button{
	display: inline-block;
	font-size: 1rem;
    font-weight: 600;
    padding: 5px 2rem;
    text-align: center;
    border:2px solid transparent;
    border-radius: 30px;
    line-height: 2;
    transition: 0.3s;
    min-width:200px;
    margin: 0 10px 1rem 10px;
}
.button:hover{
	text-decoration: none;
}


.button--small{
	padding: 0px 1.5rem;
    min-width: unset;
    font-size: 0.9rem;
    margin: 0 0 1rem 0;
}

.button--grey{
	background-color: $grey;
	color: white;
	&:hover{
		border:2px solid $grey;
		background-color: white;
		color: $grey;
	}
}

.button--red{
	background-color: $red;
	color: white;
	&:hover, &.selected{
		border:2px solid $red;
		background-color: white;
		color: $red;
	}
}

.button--yellow{
	background-color: $yellow;
	color: white;
	&:hover, &.selected{
		border:2px solid $yellow;
		background-color: white;
		color: $yellow;
	}
}

.button--green{
	background-color: $green;
	color: white;
	&:hover, &.selected{
		border:2px solid $green;
		background-color: white;
		color: $green;
	}
}

.button--blue{
	background-color: $blue;
	color: white;
	&:hover, &.selected{
		border:2px solid $blue;
		background-color: white;
		color: $blue;
	}
}

.button--black-transparent{
	background-color: transparent;
	color: black;
	&:hover, &.selected{
		border-color:black;
		color: black;
	}
}

.button--white{
	background-color: white;
	color: black;
	&:hover, &.selected{
		border:2px solid white;
		background-color: transparent !important;
		color: white !important;
	}
}